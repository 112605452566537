import { createSelector } from '@ngrx/store';
import { AppStateInterface } from '../state/app.state';
import { ProfileStoreInterface } from '../models/profile-store.interface';

export const selectProfile = (state: AppStateInterface) => state.profile;

export const profileSelector = createSelector(
  selectProfile,
  (state: ProfileStoreInterface) => state,
);

export const uuidSelector = createSelector(
  selectProfile,
  (state: ProfileStoreInterface) => state.uuid,
);

export const isUserToMobileTestSelector = createSelector(
  selectProfile,
  (state: ProfileStoreInterface) => state.isUserToMobile,
);

export const emailSelector = createSelector(
  selectProfile,
  (state: ProfileStoreInterface) => state.email,
);

export const eventIdSelector = createSelector(
  selectProfile,
  (state: ProfileStoreInterface) => state.eventId,
);

export const hasOpenTicketSelector = createSelector(
  selectProfile,
  (state: ProfileStoreInterface) => state.hasOpenTicket,
);

export const needConfirmLastTicketSelector = createSelector(
  selectProfile,
  (state: ProfileStoreInterface) => state.needConfirmLastTicket,
);

export const progressBarSelector = createSelector(
  selectProfile,
  (state: ProfileStoreInterface) => state.progressBar,
);

export const needConfirmCloseUnsolvedTicketSelector = createSelector(
  selectProfile,
  (state: ProfileStoreInterface) => state.needConfirmCloseUnsolvedTicket,
);

export const coinPickerSelector = createSelector(
  selectProfile,
  (state: ProfileStoreInterface) => state.coinPicker,
);

export const personalInfoSelector = createSelector(
  selectProfile,
  (state: ProfileStoreInterface) => state.personalInfo,
);

export const isUserMultiTaskSelector = createSelector(
  selectProfile,
  (state: ProfileStoreInterface) => state.isUserMultiTask,
);
