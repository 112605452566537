import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '../../shared/services/auth.service';
import {
  getProfile,
  setMultiTaskStatus,
  setMultiTaskSuccessStatus,
  setProfile,
} from '../actions/profile.actions';
import { map, mergeMap } from 'rxjs/operators';
import { CookieService } from '../../shared/services/cookie.service';
import { MultiChatService } from '../../shared/services/multi-chat.service';

@Injectable()
export class ProfileEffects {
  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfile),
      mergeMap(() =>
        this.authService.getProfileData(CookieService.getCookie('uuid')).pipe(
          map((response) => {
            return setProfile({
              uuid: response.data.user.uuid,
              email: response.data.user.email,
              eventId: response.data.user.event_id,
              progressBar: response.data.user.progress_bar_version === 'on',
              hasOpenTicket: response.data.user.has_open_ticket,
              needConfirmLastTicket: response.data.user.need_confirm_close_last_ticket,
              coinPicker: response.data.user.popup_coin_picker,
              needConfirmCloseUnsolvedTicket: response.data.user.need_confirm_close_unsolved_ticket,
              personalInfo: response.data.profile,
              isUserMultiTask: response.data.user.is_multitask,
            });
          }),
        ),
      ),
    ),
  );

  setMultiTaskStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setMultiTaskStatus),
      mergeMap(() =>
        this.multiChatService
          .setUserMultiTask(1)
          .pipe(
            map((response) =>
              setMultiTaskSuccessStatus({ isUserMultiTask: response.status === 'success' }),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private multiChatService: MultiChatService,
  ) {}
}
