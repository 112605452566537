import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../store/state/app.state';
import { eventIdSelector } from '../../store/selectors/profile.selectors';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ClickhouseService {
  private eventId: string = '';

  constructor(private api: ApiService, private store: Store<AppStateInterface>) {
    this.store.select(eventIdSelector).subscribe((eventId) => {
      this.eventId = eventId;
    });
  }

  storeEvent(eventName: string, value?: string, authToken?: string) {
    const formData = new FormData();
    formData.append('event_id', this.eventId);
    formData.append('event_type', eventName);
    formData.append('web', eventName);
    if (value && value !== 'undefined') {
      formData.append('value', value);
    }
    if (authToken) {
      formData.append('token', authToken);
    }
    return this.api.clickHousePost('/store', formData);
  }

  showEvent(eventType: string) {
    const formData = new FormData();
    formData.append('event_id', this.eventId);
    if (eventType && eventType !== 'undefined') {
      formData.append('event_type', eventType);
    }
    return this.api.clickHousePost('/show', formData);
  }

  showValueEvent(eventType: string) {
    const formData = new FormData();
    formData.append('event_id', this.eventId);
    if (eventType && eventType !== 'undefined') {
      formData.append('event_type', eventType);
    }
    return this.api.clickHousePost('/show/value', formData);
  }
}
